import React from 'react'
import styled from 'styled-components'
import { BigNumber } from 'bignumber.js'
import numeral from 'numeral'

import { black, primary, white } from '../../../colors'

import Container from '../../../components/Container'
import KBlock from '../../../components/KBlock'

import DaiIcon from '../../../components/icons/Dai'
import EthIcon from '../../../components/icons/Eth'
import UsdcIcon from '../../../components/icons/Usdc'

const bigToDec = (value, decimals) => {
  const val = new BigNumber(value).dividedBy(new BigNumber(10).pow(decimals))
  return val.toFixed()
}

const StyledHero = styled.div`
  background: repeating-linear-gradient(
    26.285deg,
    transparent,
    transparent 2px,
    ${black[900]} 2px,
    ${black[900]} 64px
  ),
  linear-gradient(
    to bottom,
    ${black[900]},
    #e235f540
  );
  background-color: ${black[900]};
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 96px);
`

const StyledHeroInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 64px 0;
  @media (max-width: 512px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`

const StyledHeadingWrapper = styled.div`
  margin-right: 24px;
`

const StyledHeading = styled.h1`
  color: ${white[50]};
  font-size: 3.5rem;
  margin: 0;
  max-width: 420px;
  @media (max-width: 512px) {
    font-size: 2rem;
  }
`
const StyledSubheading = styled.h3`
  color: ${white[50]};
  font-weight: 400;
  line-height: 2;
  opacity: 0.8;
  max-width: 420px;
  margin: 48px 0;
  @media (max-width: 512px) {
    font-size: 1;
  }
`
const StyledHeroImgWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    @media (max-width: 512px) {
      margin-bottom: 24px;
    }
`
const StyledHeroImg = styled.div`
  max-width: 400px;
  @media (max-width: 512px) {
    max-width: 128px;
  }
`

const StyledOverview = styled.div`
`
const StyledOverviewInner = styled.div`
  padding: 24px 0 24px;
  @media (max-width: 512px) {
    padding: 12px 0;
  }
`
const StyledOverviewContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  justify-content: flex-start;
`
const StyledOverviewTitle = styled.h3`
  color: ${white[50]};
  opacity: 0.66;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (max-width: 512px) {
    font-size: 1rem;
  }
`

const StyledLink = styled.a`
  align-items: center;
  background-color: ${primary[400]};
  color: ${white[50]};
  display: flex;
  font-weight: 500;
  height: 44px;
  padding: 0 24px;
  text-decoration: none;
  &:hover {
    background-color: ${primary[500]};
  }
`


const subheadingText = `
Kollateral aggregates liquidity from the
 largest pools and surfaces it to developers
 with a simple interface.
`

const Hero = ({ ethUsd, liquidity }) => {
  const {
    dai: daiLiquidity,
    eth: ethLiquidity,
    usdc: usdcLiquidity,
  } = liquidity ? liquidity : {
    dai: false,
    eth: false,
    usdc: false,
  }

  return (
    <StyledHero>
      <Container flex={1}>
        <StyledHeroInner>
          <StyledHeadingWrapper>
            <StyledHeading>The <span style={{ color: primary[400] }}>Flash Loan</span> Building Block.</StyledHeading>
            <StyledSubheading>{subheadingText}</StyledSubheading>
            <div style={{ display: 'flex' }}>
              <StyledLink href="https://docs.kollateral.co">Get Started</StyledLink>
            </div>
          </StyledHeadingWrapper>
          <StyledHeroImgWrapper>
            <StyledHeroImg>
              <KBlock colorGroup={[primary[300], primary[400], primary[500]]} />
            </StyledHeroImg>
          </StyledHeroImgWrapper>
        </StyledHeroInner>
      </Container>
      <StyledOverview>
        <Container>
          <StyledOverviewInner>
            <StyledOverviewTitle>Available Liquidity</StyledOverviewTitle>
            <StyledOverviewContent>
              <Liquidity
                icon={<EthIcon size={36} />}
                label="Ether"
                value={'$'+numeral(bigToDec(ethLiquidity, 18) * ethUsd).format('0.00a')}
              />
              <Liquidity
                icon={<DaiIcon size={36} />}
                label="DAI"
                value={'$'+numeral(bigToDec(daiLiquidity, 18)).format('0.00a')}
              />
              <Liquidity
                icon={<UsdcIcon size={36} />}
                label="USDC"
                value={'$'+numeral(bigToDec(usdcLiquidity, 6)).format('0.00a')}
              />
            </StyledOverviewContent>
          </StyledOverviewInner>
        </Container>
      </StyledOverview>
    </StyledHero>
  )
}

const StyledLiquidity = styled.div`
  align-items: center;
  background-color: ${black[600]};
  display: flex;
  flex: 1;
  padding: 32px 16px;
  margin: 12px;
  @media (max-width: 512px) {
    padding: 32px 16px;
    margin: 6px 12px;
  }
`
const StyledLiquidityContent = styled.div`
`

const StyledLabel = styled.h3`
  color: ${white[50]};
  font-weight: 400;
  opacity: 0.8;
  margin: 0;
`
const StyledValue = styled.h2`
  color: ${white[50]};
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
`

const StyledIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 64px;
  height: 64px;
`

const Liquidity = ({ icon, label, value }) => (
  <StyledLiquidity>
    <StyledIcon>{icon}</StyledIcon>
    <StyledLiquidityContent>
      <StyledValue>{value}</StyledValue>
      <StyledLabel>{label}</StyledLabel>
    </StyledLiquidityContent>
  </StyledLiquidity>
)

export default Hero