import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.h3`
    display: flex;
    margin: 0;
    padding: 0 24px;
`

const CardTitle = ({ children }) => (
  <StyledTitle>
    {children}
  </StyledTitle>
)

export default CardTitle