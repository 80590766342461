import React from 'react'

const Usdc = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="2 2 20 20"
    fill="none"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="#2775CA"
    />
    <path
      d="M10.1667 18.9167C10.1667 19.1667 10 19.25 9.75 19.25C6.66667 18.25 4.5 15.4167 4.5 12.0834C4.5 8.75003 6.66667 5.9167 9.75 4.9167C10 4.83336 10.1667 5.00003 10.1667 5.25003V5.83336C10.1667 6.00003 10.0833 6.1667 9.91667 6.25003C7.5 7.1667 5.83333 9.4167 5.83333 12.0834C5.83333 14.75 7.58333 17.0834 9.91667 17.9167C10.0833 18 10.1667 18.1667 10.1667 18.3334V18.9167Z"
      fill="white"
    />
    <path
      d="M12.6666 16.8333C12.6666 16.9999 12.4999 17.1666 12.3333 17.1666H11.6666C11.4999 17.1666 11.3333 16.9999 11.3333 16.8333V15.8333C9.99992 15.6666 9.33325 14.9166 9.08325 13.8333C9.08325 13.6666 9.16659 13.4999 9.33325 13.4999H10.0833C10.2499 13.4999 10.3333 13.5833 10.4166 13.7499C10.5833 14.3333 10.9166 14.8333 11.9999 14.8333C12.8333 14.8333 13.4166 14.4166 13.4166 13.7499C13.4166 13.0833 13.0833 12.8333 11.9166 12.6666C10.1666 12.4166 9.33325 11.9166 9.33325 10.4999C9.33325 9.41659 10.1666 8.58325 11.3333 8.41659V7.41659C11.3333 7.24992 11.4999 7.08325 11.6666 7.08325H12.3333C12.4999 7.08325 12.6666 7.24992 12.6666 7.41659V8.41659C13.6666 8.58325 14.3333 9.16659 14.4999 10.0833C14.4999 10.2499 14.4166 10.4166 14.2499 10.4166H13.5833C13.4166 10.4166 13.3333 10.3333 13.2499 10.1666C13.0833 9.58325 12.6666 9.33325 11.9166 9.33325C11.0833 9.33325 10.6666 9.74992 10.6666 10.3333C10.6666 10.9166 10.9166 11.2499 12.1666 11.4166C13.9166 11.6666 14.7499 12.1666 14.7499 13.5833C14.7499 14.6666 13.9166 15.5833 12.6666 15.8333V16.8333Z"
      fill="white"
    />
    <path
      d="M14.2499 19.2501C13.9999 19.3334 13.8333 19.1667 13.8333 18.9167V18.3334C13.8333 18.1667 13.9166 18.0001 14.0833 17.9167C16.4999 17.0001 18.1666 14.7501 18.1666 12.0834C18.1666 9.41675 16.4166 7.08341 14.0833 6.25008C13.9166 6.16675 13.8333 6.00008 13.8333 5.83341V5.25008C13.8333 5.00008 13.9999 4.91675 14.2499 4.91675C17.2499 5.91675 19.4999 8.75008 19.4999 12.0834C19.4999 15.4167 17.3333 18.2501 14.2499 19.2501Z"
      fill="white"
    />
  </svg>
)

export default Usdc