import React from 'react'
import styled from 'styled-components'

import { grey, primary } from '../../colors'

const StyledContent = styled.div`
    flex: 1;
`

const CardContent = ({ children }) => (
  <StyledContent>
    {children}
  </StyledContent>
)

export default CardContent