import React from 'react'

import { grey } from '../../colors'

const KBlock = ({
  colorGroup = grey,
  size = 128,
}) => (
    <svg width="100%" height="100%" viewBox="0 0 256 320">
      <path d="M0,96l64,32l64,-32l0,-64l-64,-32l-64,32l0,64Z" fill={colorGroup[0]} />
      <path d="M0,32l64,-32l64,32l-64,32l-64,-32Z" fill={colorGroup[0]} />
      <path d="M0,32l0,192l64,32l0,-192l-64,-32Z" fill={colorGroup[1]} />
      <path d="M128,32l0,192l-64,32l0,-192l64,-32Z" fill={colorGroup[2]} />
      <path d="M64,192l64,32l64,-32l0,-64l-64,-32l-64,32l0,64Z" fill={colorGroup[0]} />
      <path d="M64,128l64,-32l64,32l-64,32l-64,-32Z" fill={colorGroup[0]} />
      <path d="M0,96l0,64l128,64l0,-64l-128,-64Z" fill={colorGroup[1]} />
      <path d="M192,128l0,64l-64,32l0,-64l64,-32Z" fill={colorGroup[2]} />
      <path d="M128,160l64,32l64,-32l0,-64l-64,-32l-64,32l0,64Z" fill={colorGroup[0]} />
      <path d="M128,96l64,-32l64,32l-64,32l-64,-32Z" fill={colorGroup[0]} />
      <path d="M128,96l0,64l64,32l0,-64l-64,-32Z" fill={colorGroup[1]} />
      <path d="M256,96l0,64l-64,32l0,-64l64,-32Z" fill={colorGroup[2]} />
      <path d="M127.907,288l64,32l64,-32l0,-64l-64,-32l-64,32l0,64Z" fill={colorGroup[0]} />
      <path d="M127.907,224l64,-32l64,32l-64,32l-64,-32Z" fill={colorGroup[0]} />
      <path d="M127.907,224l0,64l64,32l0,-64l-64,-32Z" fill={colorGroup[1]} />
      <path d="M255.907,224l0,64l-64,32l0,-64l64,-32Z" fill={colorGroup[2]} />
    </svg>
  )

export default KBlock