import React, { useState } from 'react'
import styled from 'styled-components'

import { grey, primary, white } from '../../../colors'

import SyntaxHighlighter from 'react-syntax-highlighter'
import { atelierPlateauLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import Card from '../../../components/Card'

const StyledTabs = styled.div`
    background-color: ${grey[100]};
    display: flex;
`
const StyledTab = styled.div`
    align-items: center;
    background-color: ${props => props.active ? white[50] : 'transparent'};
    border-top: 2px solid ${props => props.active ? props.color : 'transparent'};
    border-right: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid ${props => props.active ? '#FFF' : 'transparent'};
    color: ${props => props.active ? grey[900] : grey[400]};
    display: flex;
    margin: 0 0 -1px;
    max-width: 256px;
    flex: 1;
    font-weight: ${props => props.active ? 700 : 400};
    height: 56px;
    justify-content: center;
    cursor: pointer;
    position: relative;
    &:hover {
        background-color: ${props => props.active ? white : grey[800]};
        color: ${grey[900]};
    }
`
const StyledActiveTab = styled.div`
    border-top: 1px solid ${grey[300]};
    box-sizing: border-box;
    padding: 24px;
`

const tabCode = [
  `import { Kollateral, Token } from '@kollateral/kollateral'

const kollateral = await Kollateral.init(ethereum);

kollateral.invoke({
    contract: myContractAddress
}, {
    token: Token.DAI,
    amount: web3.utils.toWei(1000)
}).then(() => console.log("success!");`,
  `import "@kollateral/contracts/invoke/KollateralInvokable.sol";

contract MyContract is KollateralInvokable {
    constructor () public { }
    
    function execute(bytes calldata data) external payable {
        // liquidate
        // swap
        // refinance
        // etc...
        repay();
    }
}`
]

const StyledHiddenWrapper = styled.div`
    @media (max-width: 512px) {
        display: none;
    }
`

const CodeTabs = () => {

  const [activeTab, setActiveTab] = useState(0)

  return (
    <Card>
      <StyledTabs>
        <StyledTab color={primary[400]} active={activeTab === 0} onClick={() => setActiveTab(0)}>invoke.js</StyledTab>
        <StyledTab color={primary[400]} active={activeTab === 1} onClick={() => setActiveTab(1)}>MyContract.sol</StyledTab>
        <StyledHiddenWrapper>
          <div style={{ flex: 1 }} />
        </StyledHiddenWrapper>
      </StyledTabs>
      <StyledActiveTab>
        <SyntaxHighlighter
          customStyle={{ backgroundColor: 'transparent' }}
          language="javascript"
          showLineNumbers={true}
          lineNumberStyle={{ color: 'red' }}
          style={atelierPlateauLight}
        >
          {tabCode[activeTab]}
        </SyntaxHighlighter>
      </StyledActiveTab>
    </Card>
  )
}

export default CodeTabs