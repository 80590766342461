import React from 'react'

const Dai = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 600 600"
    fill="none"
  >
    <circle cx="300" cy="300" r="300" fill="#F9A606" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.223 158.721H300.502C370.014 158.721 422.706 196.084 442.308 250.451H477.907V283.311H449.805C450.355 288.504 450.636 293.794 450.636 299.169V299.976C450.636 306.027 450.28 311.976 449.585 317.802H477.907V350.662H441.631C421.512 404.273 369.228 441.279 300.502 441.279H186.223V350.662H146.512V317.802H186.223V283.311H146.512V250.451H186.223V158.721ZM218.17 350.662V411.801H300.502C351.308 411.801 389.054 387.314 406.625 350.662H218.17ZM416.413 317.802H218.17V283.311H416.464C417.199 288.739 417.574 294.303 417.574 299.976V300.783C417.574 306.587 417.182 312.269 416.413 317.802ZM300.502 188.15C351.542 188.15 389.402 213.284 406.867 250.451H218.17V188.15H300.502Z"
      fill="white"
    />
  </svg>
)

export default Dai