import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExchangeAlt,
    faFunnelDollar,
    faWater,
} from '@fortawesome/free-solid-svg-icons'

import Card from '../../../components/Card'
import CardContent from '../../../components/Card/CardContent'
import CardIcon from '../../../components/Card/CardIcon'
import CardText from '../../../components/Card/CardText'
import CardTitle from '../../../components/Card/CardTitle'

import {
  primary,
  secondary,
  tertiary,
  white,
} from '../../../colors'

import Container from '../../../components/Container'

const StyledIntroWrapper = styled.div`
  background-color: ${white[50]};
  padding: 192px 0;
  @media (max-width: 512px) {
    padding: 64px 0;
  }
`

const StyledIntroInner = styled.div`
`

const StyledCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 12px;
  min-width: 300px;
  > * {
    flex: 1;
  }
`

const StyledTitle = styled.h1`
  line-height: 1.25;
  margin: 0 0 48px;
  font-size: 2.5rem;
  max-width: 712px;
  @media (max-width: 512px) {
    font-size: 2rem;
  }
`

const cardText1 = `
    Perform DEX arbitrage with 0 upfront capital.
`
const cardText2 = `
    Build loan refinancers.
`
const cardText3 = `
    Liquidate under-collateralized loans on all of the popular lending platforms.
`

const Intro = () => {
  return (
    <StyledIntroWrapper>
      <Container>
        <StyledIntroInner>

          <StyledTitle>
            Kollateral powers a more efficient financial system
          </StyledTitle>
          <StyledCards>
            <StyledCardWrapper>
              <IntroCard
                icon={<FontAwesomeIcon color={primary[400]} icon={faExchangeAlt} />}
                text={cardText1}
                title="Arbitrage"
              />
            </StyledCardWrapper>
            <StyledCardWrapper>
              <IntroCard
                icon={<FontAwesomeIcon color={tertiary[400]} icon={faFunnelDollar} />}
                text={cardText2}
                title="Refinance"
              />
            </StyledCardWrapper>
            <StyledCardWrapper>
              <IntroCard
                icon={<FontAwesomeIcon color={secondary[400]} icon={faWater} />}
                text={cardText3}
                title="Liquidate"
              />
            </StyledCardWrapper>
          </StyledCards>

        </StyledIntroInner>
      </Container>
    </StyledIntroWrapper>
  )
}

const IntroCard = ({ icon, text, title }) => (
  <Card>
      <CardIcon>
          {icon}
      </CardIcon>
      <CardTitle>{title}</CardTitle>
      <CardContent>
          <CardText>{text}</CardText>
      </CardContent>
  </Card>
)

export default Intro