import React, { useEffect, useState } from 'react'
import Web3 from 'web3'
import { Kollateral, Token } from '@kollateral/kollateral'

import Layout from '../Layout'
import Seo from '../Seo'

import Hero from './Hero'
import Implement from './Implement'
import Intro from './Intro'

const web3 = new Web3('https://mainnet.infura.io/v3/a3b2c2c31e2b428f9f89532e207a1f2f')
const uniswapAbi = [{
	"name": "getEthToTokenInputPrice",
	"outputs": [
	  {
	    "type": "uint256",
	    "name": "out"
	  }
	],
	"inputs": [
	  {
	    "type": "uint256",
	    "name": "eth_sold"
	  }
	],
	"constant": true,
	"payable": false,
	"type": "function",
	"gas": 5542
}];

const Home = () => {

  const [ethUsd, setEthUsd] = useState(0)
  const [kollateral, setKollateral] = useState(null)
  const [totalLiquidity, setTotalLiquidity] = useState(null)

  const getEthPrice = async () => {
    const uniswap = new web3.eth.Contract(uniswapAbi, "0x97deC872013f6B5fB443861090ad931542878126");
    const ethPriceUsdc = await uniswap.methods.getEthToTokenInputPrice(web3.utils.toWei("1")).call();
    setEthUsd(ethPriceUsdc / 1000000)
  }

  useEffect(() => {
    Kollateral.init(web3.currentProvider).then(k => {
      window.kollateral = k
      window.Token = Token
      setKollateral(k)
    })
    getEthPrice()
  }, [web3])

  useEffect(() => {
    if (kollateral) {

      async function fetchLIquidity () {
        const daiLiquidity = await kollateral.totalLiquidity(Token.DAI)
        const ethLiquidity = await kollateral.totalLiquidity(Token.ETH)
        const usdcLiquidity = await kollateral.totalLiquidity(Token.USDC)
        setTotalLiquidity({
          dai: daiLiquidity,
          eth: ethLiquidity,
          usdc: usdcLiquidity,
        })
      }

      fetchLIquidity()
    }
  }, [kollateral])

  return (
    <Layout>
      <Seo />
      <Hero ethUsd={ethUsd} liquidity={totalLiquidity} />
      <Implement />
      <Intro />
    </Layout>
  )
}

export default Home
