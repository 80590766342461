import React from 'react'
import styled from 'styled-components'

const StyledText = styled.div`
    display: flex;
    padding: 24px;
    opacity: 0.8;
`

const CardText = ({ children }) => (
  <StyledText>
    {children}
  </StyledText>
)

export default CardText