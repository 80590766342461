import React from 'react'
import styled from 'styled-components'

import { grey, primary } from '../../../colors'

import Container from '../../../components/Container'

import CodeTabs from './CodeTabs'

const StyledImplement = styled.div`
  background-color: ${grey[100]};
  padding: 192px 0;
  @media (max-width: 1024px) {
      padding: 64px 0;
  }
`

const StyledImplementTitle = styled.h2`
  margin-top: 0;
  font-size: 2.5rem;
  @media (max-width: 512px) {
    font-size: 2rem;
  }
`

const StyledImplementText = styled.span`
  max-width: 512px;
`

const StyledWrapper = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const StyledCodeWrapper = styled.div`
  flex: 4;
  margin-left: 48px;
  @media (max-width: 1024px) {
      flex: none;
      margin-left: 0px;
      margin-top: 12px;
  }
`

const StyledLeft = styled.div`
  flex: 2;
  @media (max-width: 512px) {
      flex: none;
  }
`

const implementText = `
Kollateral uses a simple pass-through architecture making
 it trivial to use within your defi flows.
`

const StyledLink = styled.a`
  border: 2px solid ${grey[300]};
  color: ${grey[900]};
  font-weight: 500;
  height: 44px;
  line-height: 44px;
  padding: 0 24px;
  text-decoration: none;
  &:hover {
      color: ${primary[400]};
  }
`

const Implement = () => (
  <StyledImplement>
    <Container>
      <StyledWrapper>
        <StyledLeft>
          <StyledImplementTitle>
            Simple Implementation
          </StyledImplementTitle>
          <StyledImplementText>{implementText}</StyledImplementText>
          <div style={{ display: 'flex', marginTop: 24, marginBottom: 48 }}>
            <StyledLink href="https://docs.kollateral.co">
              View Docs
            </StyledLink>
          </div>
        </StyledLeft>
        <StyledCodeWrapper>
          <CodeTabs />
        </StyledCodeWrapper>
      </StyledWrapper>
    </Container>
  </StyledImplement>
)

export default Implement