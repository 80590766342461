import React from 'react'
import styled from 'styled-components'

const StyledIcon = styled.div`
    display: flex;
    margin: 24px;
    font-size: 24px;
`

const CardIcon = ({ children }) => (
  <StyledIcon>
    {children}
  </StyledIcon>
)

export default CardIcon