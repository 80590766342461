import React from 'react'
import styled from 'styled-components'

import { grey } from '../../colors'

const StyledCard = styled.div`
    background: #FFF;
    border: 1px solid ${grey[300]};
    display: flex;
    flex-direction: column;
`

const Card = ({ children }) => (
  <StyledCard>
    {children}
  </StyledCard>
)

export default Card